.section-quest {
  width: 100%;
  height: 100%;
  /* min-height: calc(100vh - 60px); */
  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 50px; */
  /* padding: 1rem 2rem; */
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .section-quest.content-thumbs-section {
  padding: 1rem 0;
} */

.section-quest.content-thumbs-section{
  align-items: flex-start;
  justify-content: flex-start;
}

.section-quest.quest-itmes-grid {
  display: grid;
  padding: 2rem;
  grid-row-gap: 3rem;
}

.section-quest.content-thumbs-section > button,
.section-quest.quest-itmes-grid > button {
  margin: 0 auto;
}

.section-quest > button:focus {
  border: unset;
  outline: 0;
  /* border-color: rgb(45, 113, 138); */
  font-size: 12pt;
}
.section-quest > button.disable {
  /* background-color: rgba(45, 113, 138, 0.6);
  border-color: rgb(45, 113, 138); */
  color: rgba(0, 0, 0, 0.3);
}

.section-quest > button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: unset;
  padding: 1rem;
  /* margin: auto; */
  cursor: pointer;

  /* background-color: rgb(45, 113, 138);
  border-color: rgb(45, 113, 138);
  font-size: 16pt; */

  border-color: #d6d6d6;
  color: rgba(0, 0, 0, 0.7);
  background-color: #d6d6d6;
  font-size: 12pt;

  transition: 0.5s font-size linear;
}

.welcolme-content {
  align-self: center;
  justify-self: center;
  padding: 2rem;
  text-align: center;
}

.welcolme-content h1 {
  margin: 0;
  font-size: 20pt;
  margin-bottom: 50px;

  font-size: 20pt;
  color: rgba(0, 0, 0, 0.7);
}

.welcolme-content h1 + p {
  font-size: 14pt;
  max-width: 500px;
}

.item-center {
  align-self: center;
  /* justify-self: center; */
  padding: 0 2rem 2rem 2rem;
}
.item-center > h2 {
  margin-top: 0;
}

.center-margin {
  margin: auto;
}

.grid-content.single {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(auto, auto);
}

.grid-content.square {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(auto, auto);
}

.grid-content.qsquare {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(auto, auto);
}

.grid-content > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  height: fit-content;

  /* background: rgba(0, 171, 168, 0.4); */

  background: rgba(0, 0, 0, 0.3);

  padding: 1rem;
}

.grid-content img {
  /* background: rgba(0, 171, 168, 0.4); */
  background: rgba(0, 0, 0, 0.3);

  width: 100%;
  max-width: 30vmin;
  margin: auto;
}
.grid-content img + span {
  font-size: 18pt;
}

.content-questions > .section-quest-item:first-child {
  margin-top: 3rem;
}

.content-questions > .section-quest-item::after {
  content: "";
  position: absolute;
  width: calc(100% - 2rem);
  border-bottom: 1px solid white;
  bottom: -2rem;
  border-radius: 6px;
}

.section-quest-item {
  /* padding: 1rem 2rem; */
  font-family: Heebo;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  max-width: 60vmax;
  /* border: solid 1.7px rgba(0, 171, 168, 0.9);
    margin: auto;
    margin-bottom: 2rem; */
}
@media (min-width: 768px) {
  .section-quest-item {
    border: solid 1.7px rgba(0, 0, 0, 0.7);
  }
}

.section-quest-item p {
  margin: 0;
  margin-bottom: 0.7rem;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14pt;
  font-weight: bold;
}
.answer-content input[type="text"],
.answer-content input[type="number"],
.answer-content textarea {
  /* color: #143845; */
  color: rgba(0, 0, 0, 0.7);
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  /* border: solid 1.7px rgba(45, 113, 138, 0.5) ; */
  border: solid 1.7px rgba(0, 0, 0, 0.3);
  font-size: 12pt;
  /* background-color: rgba(45, 113, 138, 0.7); */
  /* font-family: Heebo; */
  width: 100%;
  box-sizing: border-box;
  /* border-color: rgb(45, 113, 138); */
}

.answer-content textarea:focus {
  outline: 0;
}

.answer-content input[type="radio"] {
  visibility: hidden;
  position: absolute;
}

.answer-content.answer-content-vertical label,
.answer-content.answer-content-horizontal label {
  padding: 0.3rem 0.5rem;
  font-size: 14pt;
}

.answer-content label {
  line-height: 1.3;
  position: relative;
}

.answer-content label.active-radio {
  /* border: #398599 1px SOLID; */
  border: rgba(0, 0, 0, 0.3) 1px SOLID;
}

.grid-content.single span {
  position: relative;
  padding-left: 1rem;
}

.grid-content.single span.active::before,
.answer-content.answer-content-vertical label.active-radio.input-radio::before {
  content: "\2B24";
  color: rgba(0, 0, 0, 0.3);
}

.grid-content.single span::before,
.answer-content.answer-content-vertical .input-radio::before {
  content: "\25EF";
  display: block;
  font-size: 7pt;
  position: absolute;
  top: 50%;
  /* left: 14px; */
  -webkit-transform: translate(-1rem, -46%);
  transform: translate(-1.1rem, -50%);
}

.answer-content.answer-content-vertical label {
  padding-left: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.answer-content.answer-content-vertical {
  display: flex;
  max-width: 100%;
  box-sizing: border-box;
  flex-direction: column;
}

.answer-content.answer-content-horizontal {
  display: flex;
  max-width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-around;
}

.quest-thumb-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(auto, 6rem);
  flex-grow: 1;
  padding: 1rem;
  position: relative;

  width: 100%;
  box-sizing: border-box;
}

div.quest-thumb-grid-item {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  padding: 0.5rem;
  box-sizing: border-box;
}

.quest-thumb-grid-item h3 {
  z-index: 57;
  position: inherit;
  color: white;
  margin: 0;
}

.fade-back-thumb {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.fade-back-thumb img {
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
}

.fade-back-thumb span {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: block;
  margin: 0;
}

.quest-thumb-grid .back-thumb {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  /* margin: 1.3rem 1.7rem; */
  font-size: 20pt;
  font-family: Montserrat;
  font-weight: bold;
  z-index: 1;
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.quest-thumb-grid .back-thumb img {
  width: 100%;
}

.quest-thumb-grid-item.check::after {
  content: "\221A";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  z-index: 2;
  font-size: 15pt;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
}

.quest-thumb-grid-item.disable::after {
  content: "\2605";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 29px;
  height: 29px;
  z-index: 2;
  font-size: 15pt;
  background-color: #00aba8;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
}

.content-thumbs-section .section-thumb-item {
  width: 100%;
  margin-bottom: 1rem;
}

.content-thumbs-section .section-thumb-item .horizontal-grid {
  grid-auto-columns: minmax(auto, auto);
  grid-template-columns: unset !important;
  width: 100%;
  max-width: 100vw;
}

.section-thumb-item .horizontal-grid > div {
  grid-row: 1;
  flex-shrink: 1;
  min-width: 7rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;
  border-radius: 10px;
}

.horizontal-grid.extended {
  display: inline-grid;
  grid-template-columns: repeat(6, 60vmin);
  max-width: 100%;
}

.horizontal-grid {
  display: inline-grid;
  grid-template-columns: 60vmin 60vmin;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}

.scroll-hide {
  overflow-x: scroll;
  max-width: 100vw;
  scroll-behavior: smooth;
}

.horizontal-grid > div {
  color: white;
  padding: 1rem 1rem 4rem 1rem;
  position: relative;
}

.horizontal-grid h4 {
  margin: 0;
}
.horizontal-grid p {
  font-size: 12pt;
  margin: 0;
  margin-top: 0.5rem;
  padding: 0 !important;
}

.horizontal-grid span {
  position: absolute;
  bottom: 0;
  right: 0;
  /* margin: 1.3rem 1rem; */
  font-family: Heebo;
}

.horizontal-grid > hr {
  grid-column: 3;
  grid-row: 1;
  visibility: hidden;
}

.section-thumb-item > h3 {
  padding-left: 1rem;
}
.quest-thumb-grid > p {
  padding: 1rem 0;
  grid-column: 1 / 3;
}
.content-thumbs-section > p {
  padding: 1rem;
}

.answer-content .input-range__track--active {
  background: #00aba8;
}

.answer-content .input-range__slider {
  background: #00aba8;
  border: 1px solid #00aba8;
}

.atomic-quest .atomic-quest-selected {
  /* outline: 2px solid #00aba8; */
  outline: 2px solid rgba(0, 0, 0, 0.7);
}

/* .atomic-quest .atomic-quest-selected{
    position: relative;
  }
  .atomic-quest .atomic-quest-selected::before{
    content: '';
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border: 1px white solid;
  } */

.content-answer-matriz {
  display: grid;
  grid-template-columns: 40% repeat(5, 1rem);
  /* grid-template-rows: 1fr; */
  grid-gap: 0.5rem 1rem;
  padding: 1rem;
  box-sizing: border-box;
  align-items: center;
}

.answer-content > .input-range {
  height: 1rem;
  position: relative;
  width: 80%;
  margin: auto;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}
