/* .content-scheduler */
.content-scheduler {
  box-sizing: border-box;
}

.content-scheduler > h2{
  background-color: rgb(46, 114, 138, 0.5);
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.content-scheduler > h2 .week-peeker{
  font-size: 12pt;
  margin-top: 8px;
}

.content-scheduler > h2 .week-peeker > span:first-child{
  margin-right: 1rem;
  font-size: 14pt;
  cursor: pointer;
}
.content-scheduler > h2 .week-peeker > span:last-child{
  margin-left: 1rem;
  font-size: 14pt;
  cursor: pointer;
}

.content-scheduler h2,
.content-scheduler h3,
.content-scheduler h4,
.content-scheduler p {
  margin: 0;
}

.content-scheduler > div.content-main-scheduler {
  display: grid;
  grid-template-columns: 320px 1fr 30%;
  column-gap: 1rem;
  /* padding: 1rem; */
}

/* .content-scheduler div.content-main-scheduler > div {
  padding: 1rem;
  box-sizing: border-box;
} */

.content-scheduler div.content-dow {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  height: fit-content;
  padding: 1rem;
  padding-top: 2rem;
  padding-left: 2rem;
  box-sizing: border-box;
}

.content-scheduler div.content-dow > .dow-item {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background-color: rgba(242, 242, 242, 0.5);
  align-items: center;
  position: relative;
}
.content-scheduler div.content-dow > .dow-item.active::before {
  content: '';
  position: absolute;
  top: 0;
  right: -42px;
  /* height: calc(100% - 50px); */
  border-left: 42px solid rgba(242, 242, 242, 0.5) ;
  border-top: 42px solid transparent;
  border-bottom: 42px solid transparent;
}



.content-scheduler div.content-dow > .dow-item > hr{
  margin-top: 0;
  margin-bottom: 0;
  height: 50px;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.content-scheduler div.content-dow > .dow-item > div:first-child {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 37%;
  flex-shrink: 1;
}
.content-scheduler div.content-dow > .dow-item > div:last-child {
  display: grid;
  grid-row-gap: 0.3rem;
  row-gap: 0.3rem;
  flex-grow: 1;
  font-size: 11pt;
}

.content-scheduler .content-main-scheduler > div.content-tod {
  display: grid;
  row-gap: 1rem;
  height: fit-content;
  box-sizing: border-box;
  padding: 1rem;
  padding-top: 2rem;
  padding-left: 2rem;
}

.content-scheduler div.content-tod > h3{
  color: rgb(46, 114, 138, 0.9);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.content-scheduler div.content-callback-task {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
}
.content-scheduler div.content-callback-task > h4 {
  grid-column: 1/4 ;
  grid-row: 1;
  color: rgb(46, 114, 138, 0.9);
  margin-bottom: 0.5rem;
}

.content-scheduler div.content-callback-task > div {
  grid-row: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(242, 242, 242, 0.4);
  border-radius: 10px;
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
}
.content-scheduler div.content-callback-task > div > img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 0.3rem;
}

.content-scheduler div.content-task {
  display: grid;
  row-gap: 1rem;
  height: fit-content;
}

/* color: rgb(46, 114, 138); */

.content-scheduler div.content-task > .item-task {
  /* background-color: #f2f2f2; */
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: rgba(46, 114, 138, 0.7);
  border-radius: 15px;
  color: white;
}

.content-scheduler div.content-task > .item-task > div:first-child {
  padding-right: 1rem;
}

.content-scheduler div.content-task > .item-task h4 {
  
  margin-bottom: 0.5rem;
}

.content-scheduler div.content-task > .item-task span{
  margin-right: 0.3rem;
}

.content-scheduler div.content-task > .item-task img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}



.content-scheduler div.content-def{
  background-color: rgba(242, 242, 242, 0.5);
  box-sizing: border-box;
  padding: 1rem;
}

.content-scheduler div.content-def > h3,
.content-scheduler div.content-def > span{
  text-align: right;
  color: rgb(46, 114, 138);
  width: 100%;
  display: block;
}


.content-scheduler div.item-proyect span.fa-remove,
.content-scheduler div.content-task span.fa-remove {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem;
  cursor: pointer;
}


.modal-content {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.modal-fade {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.modal-content > .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #f2f2f2; */
  background-color: #fff;
  width: 60vmin;
  padding: 1rem 2rem;
  z-index: 2;
}

.modal-content > .modal > .modal-section-top {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-content  .modal > .modal-section-body{
  padding-bottom: 0.5rem;
}

.modal-content > .modal h4 {
  margin-bottom: 1rem;
  color: rgb(46, 114, 138);
}

.modal-content  .modal-section-body > div {
display: grid;
  row-gap: 1rem;
}

.modal-content > .modal div.input-content {
  display: flex;
  flex-direction: column;
  
}

.modal-content > .modal div.input-content > label {
  margin-bottom: 5px;
}

.modal-content > .modal div.input-content > input {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
  background-color: #f2f2f2;
}

.modal-content > .modal div.input-content > select {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
  box-sizing: border-box;
  background-color: #f2f2f2;
}

.modal-content > .modal div.input-content > textarea {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
  box-sizing: border-box;
  background-color: #f2f2f2;
}

.modal-content > .modal div.input-content > input:hover {
  border: unset;
}

.input-content > .content-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.input-content > .content-options > label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.input-content > .content-options > label > span {
  margin-bottom: 5px;
}
.input-content > .content-options > label > input {
  margin: auto;
}

.modal-content > .modal > div:last-child > span {
  margin: auto;
  display: block;
  padding: 0.7rem 1rem;
  background: rgba(46, 114, 138, 0.8);
  font-family: Montserrat;
  font-size: 11pt;
  border: unset;
  color: white;
  width: fit-content;
  margin-top: 1rem;
  cursor: pointer;
}



.alert-confirm {
  background-color: rgb(46, 114, 138);
  color: white;
  padding: 1rem;
  width: fit-content;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.alert-confirm p {
  margin-bottom: 1rem;
}

.alert-confirm > div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.item-notification.notification-danger {
  background-color: #e65100;
  color: white;
}
.item-notification.notification-warning {
  background-color: #ffa000;
  color: white;
}
.item-notification.notification-success {
  background-color: #2d718a;
  color: white;
}

.content-task-notification {
  display: grid;
  row-gap: 1rem;
  margin-top: 1rem;
}

.content-task-notification > div.item-notification {
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
}

.content-task-notification > div.item-notification > span {
  position: absolute;
  top: 5px;
  right: 5px;
}
