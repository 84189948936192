@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Lato:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Heebo);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'SimplonBP';
  /* src: url('http://personas.bioticasostenible.com/fuentes/SimplonBP-Bold.otf'); */
  /* src: url('https://personastest.bioticasostenible.com/fuentes/SimplonBP-Bold.otf'); */
  src: url('https://unruffled-engelbart-aabf47.netlify.app/fuentes/SimplonBP-Bold.otf');
  font-weight: bold;
	font-style: normal;
}

@font-face {
  font-family: 'SimplonBP';
  /* src: url('http://personas.bioticasostenible.com/fuentes/SimplonBP-Medium.otf'); */
  /* src: url('https://personastest.bioticasostenible.com/fuentes/SimplonBP-Medium.otf'); */
  src: url('https://unruffled-engelbart-aabf47.netlify.app/fuentes/SimplonBP-Medium.otf');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'SimplonBPRegular';
  /* src: url('http://personas.bioticasostenible.com/fuentes/SimplonBPRegular.otf'); */
  /* src: url('https://personastest.bioticasostenible.com/fuentes/SimplonBPRegular.otf'); */
  src: url('https://unruffled-engelbart-aabf47.netlify.app/fuentes/SimplonBPRegular.otf');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'SimplonBPMono';
  /* src: url('http://personas.bioticasostenible.com/fuentes/SimplonBPMono-Regular.otf'); */
  /* src: url('https://personastest.bioticasostenible.com/fuentes/SimplonBPMono-Regular.otf'); */
  src: url('https://unruffled-engelbart-aabf47.netlify.app/fuentes/SimplonBPMono-Regular.otf');
  font-weight: normal;
	font-style: normal;
}


body{
  font-family: 'Lato', sans-serif;
  overscroll-behavior: none;
}

.switch-wrapper{
  overflow: hidden;
}

.App{
  width: 100vw;
  height: 100vh;
  
  position: relative;

  display: grid;
  grid-template-columns: 15vw 1fr ;
  grid-template-rows: auto 1fr;
  grid-template-areas: 
    "header header"
    "main main";
}

@media(min-width:1024px){

  .App{
    grid-template-areas: 
      "header header"
      "sidebar main";
  }

  aside{
    display: flex !important;
  }
}

.App.question{
  width: 100%;
  height: 100%;
  position: fixed;

  display: grid;
  grid-template-columns: 15vw 1fr ;
  grid-template-rows: auto 1fr;
  grid-template-areas: 
    "header header"
    "main main";
}

.App.quest-content{
  grid-template-areas: 
    "header header"
    "main main";
}

header{
  grid-area: header;
}
aside{
  grid-area: sidebar;
}

main{
  grid-area: main;
}


/*Header*/
header{
  min-height: 70px;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  /* background-color: rgb(45,113,138); */
  background-color: #000;

  display: grid;
  grid-template-columns: 13vw 1fr ;
  z-index: 5;
  color: white;
}

header > div.aside-left{
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items : center;
}

/* header > div.aside-left > span.fa{
  color: #B3CCE4;
} */


header > div.aside-left img{
  height: 7vmin;
  margin-left: 1rem;
  margin-bottom:-5px;
}

.colapse-menu{
  position: absolute;
  width: 150px;
  right: -300px;
  padding: 1rem;
  background: rgb(0,0,0);
  text-align: left;
  /* opacity: 0;  */
  top: 42px;
  transition: 1.3s all ease-out;
}

.colapse-menu.active{
  /* opacity: 1;  */
  top: 42px;
  right: -20px;
}

@media(min-width:768px){
  header > div.aside-left img{
    height: 3.5vmin;
  }
}

@media(min-width:1024px){
  header > div.aside-left img{
    height: 4vmin;
  }
}


header > div.aside-rigth{
  display: flex;
  justify-content: flex-end;
  padding: 0.3rem 1rem;
  align-items: center;
}

header > div.aside-rigth .content-avatar{
  display: flex;
  align-items: center;
}

.content-avatar i{
  font-size: 18pt;
}

.content-avatar span.logout{
  margin-left: 20px;
}

.content-avatar span.logout,
.content-avatar a:first-child,
.content-avatar span:first-child,
.content-avatar .colapse-menu a{
  /* font-family: Montserrat; */
  font-family: SimplonBP;
  /* font-family: SimplonBPRegular; */
  /* font-family: SimplonBPMono; */
  /* color: #398599; */
  font-size: 14pt;
  font-weight: bold;
  margin-right: 1rem;
  text-decoration: none;
  color: white;
}

.content-avatar .colapse-menu{
  display: grid;
  grid-row-gap: 10px ;
}

.content-avatar .colapse-menu a{
  display: block;
}


.content-avatar span.img-avatar{
  width: 30px;
  height: 30px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  border-radius: 25px;
  margin-left: 0.7rem;
}

/*end Header*/


/*Menu*/

aside{
  /* display: flex; */
  display: none;

  flex-direction: column;
  /* font-family: Heebo; */
  /* font-family: SimplonBP; */
  font-family: SimplonBPRegular;
  /* font-family: SimplonBPMono; */
  z-index: 3;
  /* background-color: rgb(224, 224, 224);
  color: rgb(46,114,138); */
  background-color: #F2F2F2;
  color: rgb(46,114,138);
  /* color: white; */
}

aside > nav{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 2rem 1.3rem;
}

aside > nav span.caption{
  /* color: #C0BEC5; */
  margin-bottom: 0.3rem;
}

aside > nav > section.section-menu:first-child{
  margin-bottom: 2rem;
}

aside > nav > section.section-menu{
  margin-bottom: 2rem;
}
aside > nav span.caption{
  margin-bottom: 1rem;
  display: block;
  text-align: left;
  font-size: 15pt;
}
aside > nav a{
  text-decoration: none;
  /* color: #474857; */
  color: rgba(0,0,0,0.7);
  /* color: rgb(46,114,138); */
  /* color: white; */
  display: block;
  margin-bottom: 0.3rem;
  font-size: 14pt;
}

aside > nav span.caption ~ a{
  margin-left: 0.7rem;
  margin-bottom: 1rem;
}

aside > nav a .fa{
  margin-right: 0.7rem;
  font-size: 13pt;
  /* color: #C0BEC5; */
  /* color: rgb(46,114,138); */
}
aside > nav a.active > span:last-child{
  /* color: #398599; */
  margin-right: 0.9rem;
  font-weight: bold;
}
/* aside > nav a.active .fa{
  display: none;
} */
/* 
aside > nav a.active > .fa{
  color: #7887C8;
} */

aside > footer{
  box-shadow: 0 -1px 0px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
  flex-shrink: 1;
  height: 50px;
  /* color: #C0BEC5; */
  /* color: rgba(255,255,255,0.9); */
  /* color: rgb(46, 114, 138, 0.9); */
  color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

/*end Menu*/


/*main*/
main{
  overflow-y: scroll;
  color: #474857;
  /* width: 100%;
  height: 100%; */
  /* color: #C0BEC5; */
}



main > div,
main > div > div  {
height: 100%;
width: 100%;
}

main h1 {
  font-size: 22pt;
  /* color: #5C65AD; */
  /* color: #398599; */
  color: rgba(0,0,0,0.7);
}
main h2 {
  font-size: 20pt;
  /* color: #5C65AD; */
  /* color: #398599; */
  color: rgba(0,0,0,0.7);
}
main h3 {
  font-size: 18pt;
}
main h4 {
  font-size: 16pt;
}
main h5 {
  font-size: 14pt;
}
main h6 {
  font-size: 12pt;
}

.main-content{
  padding: 5vmin;
  box-sizing: border-box;
}

.section-main{
  margin-bottom: 5vmin;
}

/*end Main*/


.content-login {
  display: grid;
  position: fixed;
  height: 100%;
  width: 100%;

  /* grid-template-columns: repeat(2, 1fr); */
  /* height: 100vh; */
  background-color: #000;
  /* background-color: #D6D6D6; */
}

.content-login .brand-content {
  background-position: top;
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.content-login .brand-content > div {
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-login .brand-content img {
  max-height: 10vmin;
  width: auto;
}

.content-login .form-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.content-login .form-content > div {
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  padding: 2rem;
  width: 400px;
  box-sizing: border-box;
  max-width: 90vmin;
}
.content-login .form-content .form-section h2 {
  color: #398599;
  box-sizing: border-box;
}

.content-login .form-section {
  box-sizing: border-box;
  position: relative;
}

.content-login .form-section input {
  border: none;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: 16pt;
  border-bottom: 1.3px solid rgba(255, 255, 255, 0.7);
  color: white;
  background-color: transparent;
  position: relative;
  outline: unset;
}

.content-login .form-section.unactive::after {
  content: "Ingrese su c\F3   digo|";
  position: absolute;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16pt;
  width: 100%;
  left: 0.5rem;
  bottom: 0.5rem;
  -webkit-animation: textInputEmpty 1.3s infinite;
          animation: textInputEmpty 1.3s infinite;
}

@-webkit-keyframes textInputEmpty {
  0% {
    content: "Ingrese su c\F3  digo|";
  }
  50% {
    content: "Ingrese su c\F3  digo";
  }
}

@keyframes textInputEmpty {
  0% {
    content: "Ingrese su c\F3  digo|";
  }
  50% {
    content: "Ingrese su c\F3  digo";
  }
}

.content-login .form-section > span {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: rgba(51, 51, 51, 0.5);
  font-size: 14pt;
}

.content-login .form-content > div button {
  padding: 0.5rem 1rem;
  font-size: 14pt;
  /* border-radius: 40px; */
  /* width: 200px; */
  /* color: white; */
  margin: auto;
  display: block;
  margin-top: 5rem;
  border: unset;
  /* background-repeat: no-repeat; */
  /* background-size: contain; */
  /* background-position: left; */
  background: none !important;
  color: rgba(255, 255, 255, 0.8);
  border: 1px rgba(255, 255, 255, 0.7) solid;
  border-radius: 5px;
  letter-spacing: 2px;
}

.content-retrospective{
    padding: 1rem;
}

.content-retrospective h2{
    margin: 0;
    margin-bottom: 2rem;
}

.content-comments{
    display: grid;
    grid-template-columns: 1rf;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.content-comments > div{
    padding: 1rem;
    background-color: #F2F2F2;
}
/* .content-breathing-tools */

.content-breathing-tools {
  padding: 1rem;
  box-sizing: border-box;
}

.content-breathing-tools h2 {
  margin: 0px;
  margin-bottom: 1rem;
}

.content-breathing-tools .content-tools {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
}

@media (min-width: 768px) {
  .content-breathing-tools .content-tools {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}

@media (min-width: 1024px) {
  .content-breathing-tools .content-tools {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
}

.content-breathing-tools .content-tools > div {
  padding: 1rem;
  box-sizing: border-box;
  background-color: #f2f2f2;
}

.content-breathing-tools .content-tools > div h4 {
  margin: 0px;
}

.content-breathing-tools .content-main-tool {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.content-breathing-tools .content-main-tool .breath-item-current {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: #f2f2f2;
}

.content-breathing-tools
  .content-main-tool
  .breath-item-current
  > div:first-child {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.content-breathing-tools
  .content-main-tool
  .breath-item-current
  > div:last-child {
  text-align: right;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.content-breathing-tools .content-main-tool .breath-item-current > div > div {
  padding: 1rem;
  box-sizing: border-box;
}

.content-breathing-tools
  .content-main-tool
  .breath-item-current
  > div
  > div:last-child
  > span {
  display: flex;
  clear: both;
  margin-bottom: 0.1rem;
  justify-content: space-between;
}

.content-breathing-tools .content-main-tool .breath-item-current h4,
.content-breathing-tools .content-main-tool .breath-item-current p {
  margin: 0;
}

/* .content-definicion */

.content-definicion {
  padding: 1rem;
  box-sizing: border-box;
}
.content-definicion h2 {
  margin: 0;
  margin-bottom: 1rem;
}

.content-definicion > .main-definicion {
  display: grid;
  grid-template-columns: 1fr 40%;
  padding: 1rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.content-definicion > .main-definicion.definicion-breath {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.content-definicion > .main-definicion.definicion-breath .content-left {
  grid-row: 2;
}

.content-definicion > .main-definicion.definicion-breath .content-rigth {
  grid-row: 1;
}

@media (min-width: 1024px) {
  .content-definicion > .main-definicion.definicion-breath {
    grid-template-columns: 1fr 40%;
  }
  .content-definicion > .main-definicion.definicion-breath .content-left {
    grid-row: 1;
  }
  .content-definicion > .main-definicion.definicion-breath .content-rigth {
    grid-row: 1;
  }
}

.content-definicion > .main-definicion h3 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 16pt;
}

.content-definicion > .main-definicion h4 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 14pt;
}

.content-definicion > .main-definicion .definition-description-content {
  margin-bottom: 1rem;
}

.content-definicion .definition-steps-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.content-definicion .definition-steps-content > div {
  padding-left: 0.5rem;
  box-sizing: border-box;
}

.content-definicion > .main-definicion p {
  margin: 0;
}

.content-definicion .main-definicion > .content-left {
  position: relative;
}
.content-definicion .main-definicion > .content-left .fade-feedback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 114, 138, 0.1);
  border-radius: 10px;
}

.content-definicion .main-definicion > .content-left > div:last-child {
  z-index: 100;
}

.content-definicion .content-callback .callback {
  margin: auto;
  display: block;
  padding: 0.7rem 1rem;
  background: rgba(46, 114, 138, 0.8);
  font-family: Montserrat;
  font-size: 11pt;
  border: unset;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 1rem;
  cursor: pointer;
}

.content-definicion .content-breath-count {
  margin-bottom: 1rem;
  text-align: right;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-definicion .content-breath-count > span {
  position: absolute;
  top: 0;
  right: 0;
  display: inline;
  color: white;
  background: rgba(46, 114, 138, 0.8);
  padding: 1rem;
  font-size: 18pt;
  border-radius: 5px;
  text-align: center;
  padding-right: 1.3rem;
}

.content-definicion .content-breath-count > span::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  font-size: 10pt;
  font-weight: bold;
}

.content-definicion .content-breath-count > span > small {
  font-size: 10pt;
  display: block;
}

.content-definicion .content-breath-count > span > select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  border: none;
}

.content-breath-indicator {
  width: 300px;
  /* height: 250px; */
  position: relative;
  display: flex;
  flex-direction: column;
}

.content-breath-indicator > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-breath-indicator .breath-time {
  font-size: 30pt;
  line-height: 0.9;
}

.content-breath-indicator .breath-time small {
  font-size: 10pt;
}
.content-breath-indicator .breath-action {
  font-size: 10pt;
}

.content-breath-indicator svg {
  transform: scale(0.7);
  position: relative;
}

.content-breath-indicator svg.breath[act="IN"] {
  -webkit-animation: BreathIn ease-out forwards;
          animation: BreathIn ease-out forwards;
  /* transform: scale(0.7); */
}
.content-breath-indicator svg.breath[act="OUT"] {
  animation: BreathIn ease-out reverse forwards;
  /* transform: scale(1); */
}
.content-breath-indicator svg.breath[act="AFTER-IN"] {
  transform: scale(1);
}

.content-breath-indicator svg.breath[act="AFTER-OUT"] {
  transform: scale(0.7);
}

.content-breath-indicator svg path {
  transition: 1s d;
}

.content-breath-init {
  position: absolute;
  z-index: 500;
  background-color: rgba(46, 114, 138, 0.5);
  /* background-color: rgba(46, 114, 138, 1); */
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-breath-init > div > select {
  border: unset;
  padding: 6px 5px 6px 7px;
  border-radius: 5px;
}

.content-breath-init > div {
  display: flex;
}

.content-breath-init > div > span {
  display: inline;
  background: white;
  color: rgb(46, 114, 138);
  padding: 5px 7px;
  /* font-size: 18pt; */
  border-radius: 5px;
  margin-left: 5px;
}

@-webkit-keyframes BreathIn {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes BreathIn {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

/* content-feedback-detail */

.content-feedback-detail {
    padding: 1rem;
  }
  
  .content-feedback-detail h2 {
    margin-bottom: 0;
  }
  
  .content-feedback-detail > small {
    display: block;
  }
  .content-feedback-detail > small + span {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .content-feedback-detail > div:not(:last-child) {
    width: 100%;
    margin-bottom: 1.2rem;
    position: relative;
  }
  
  .content-feedback-detail textarea {
    width: 100%;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid white;
    height: 130px;
    box-sizing: border-box;
    font-family: Heebo;
    line-height: 1.2;
    font-size: 11pt;
    z-index: 2;
    position: relative;
    color: #333;
  }
  .content-feedback-detail textarea.tEmpty {
    background-color: rgba(255, 255, 255, 0);
  }
  
  .content-feedback-detail textarea + span {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid white;
    height: 130px;
    box-sizing: border-box;
    font-family: Heebo;
    line-height: 1.2;
    font-size: 11pt;
    opacity: 0;
    color: #6d6d6d;
  }
  
  .content-feedback-detail textarea.tEmpty + span {
    opacity: 1;
  }
  
  .content-feedback-detail button {
    margin: auto;
    display: block;
    padding: 0.7rem 1rem;
    background: white;
    font-family: Montserrat;
    font-size: 11pt;
    border: unset;
  }
  .content-feedback-detail button.disable {
    background: rgb(255, 255, 255, 0.7);
    color: #6d6d6d;
  }
  
  .content-feedback-detail > .content-description{
      margin-bottom: 1rem;
      display: block;
  }

  .content-feedback-detail .content-feedback-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .content-feedback-detail .content-feedback-options .content-feedback-options-item .check,
  .content-feedback-detail .content-feedback-options .content-feedback-options-item .check-dash {
    display: none;
  }
  
  .content-feedback-detail .content-feedback-options .content-feedback-options-item.active .check,
  .content-feedback-detail .content-feedback-options .content-feedback-options-item.active .check-dash {
    display: inherit;
  }

  .content-feedback-options-item .option-icon-check{
      display: block;
      width: 30px;
      height: 30px;
      margin: auto ;
  }
  
  .content-feedback-detail .content-feedback-options-item {
    font-size: 10pt;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
/* .content-scheduler */
.content-scheduler {
  box-sizing: border-box;
}

.content-scheduler > h2{
  background-color: rgb(46, 114, 138, 0.5);
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.content-scheduler > h2 .week-peeker{
  font-size: 12pt;
  margin-top: 8px;
}

.content-scheduler > h2 .week-peeker > span:first-child{
  margin-right: 1rem;
  font-size: 14pt;
  cursor: pointer;
}
.content-scheduler > h2 .week-peeker > span:last-child{
  margin-left: 1rem;
  font-size: 14pt;
  cursor: pointer;
}

.content-scheduler h2,
.content-scheduler h3,
.content-scheduler h4,
.content-scheduler p {
  margin: 0;
}

.content-scheduler > div.content-main-scheduler {
  display: grid;
  grid-template-columns: 320px 1fr 30%;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
  /* padding: 1rem; */
}

/* .content-scheduler div.content-main-scheduler > div {
  padding: 1rem;
  box-sizing: border-box;
} */

.content-scheduler div.content-dow {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem;
  padding-top: 2rem;
  padding-left: 2rem;
  box-sizing: border-box;
}

.content-scheduler div.content-dow > .dow-item {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background-color: rgba(242, 242, 242, 0.5);
  align-items: center;
  position: relative;
}
.content-scheduler div.content-dow > .dow-item.active::before {
  content: '';
  position: absolute;
  top: 0;
  right: -42px;
  /* height: calc(100% - 50px); */
  border-left: 42px solid rgba(242, 242, 242, 0.5) ;
  border-top: 42px solid transparent;
  border-bottom: 42px solid transparent;
}



.content-scheduler div.content-dow > .dow-item > hr{
  margin-top: 0;
  margin-bottom: 0;
  height: 50px;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.content-scheduler div.content-dow > .dow-item > div:first-child {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 37%;
  flex-shrink: 1;
}
.content-scheduler div.content-dow > .dow-item > div:last-child {
  display: grid;
  grid-row-gap: 0.3rem;
  grid-row-gap: 0.3rem;
  row-gap: 0.3rem;
  flex-grow: 1;
  font-size: 11pt;
}

.content-scheduler .content-main-scheduler > div.content-tod {
  display: grid;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-sizing: border-box;
  padding: 1rem;
  padding-top: 2rem;
  padding-left: 2rem;
}

.content-scheduler div.content-tod > h3{
  color: rgb(46, 114, 138, 0.9);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.content-scheduler div.content-callback-task {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
}
.content-scheduler div.content-callback-task > h4 {
  grid-column: 1/4 ;
  grid-row: 1;
  color: rgb(46, 114, 138, 0.9);
  margin-bottom: 0.5rem;
}

.content-scheduler div.content-callback-task > div {
  grid-row: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(242, 242, 242, 0.4);
  border-radius: 10px;
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
}
.content-scheduler div.content-callback-task > div > img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 0.3rem;
}

.content-scheduler div.content-task {
  display: grid;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

/* color: rgb(46, 114, 138); */

.content-scheduler div.content-task > .item-task {
  /* background-color: #f2f2f2; */
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: rgba(46, 114, 138, 0.7);
  border-radius: 15px;
  color: white;
}

.content-scheduler div.content-task > .item-task > div:first-child {
  padding-right: 1rem;
}

.content-scheduler div.content-task > .item-task h4 {
  
  margin-bottom: 0.5rem;
}

.content-scheduler div.content-task > .item-task span{
  margin-right: 0.3rem;
}

.content-scheduler div.content-task > .item-task img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}



.content-scheduler div.content-def{
  background-color: rgba(242, 242, 242, 0.5);
  box-sizing: border-box;
  padding: 1rem;
}

.content-scheduler div.content-def > h3,
.content-scheduler div.content-def > span{
  text-align: right;
  color: rgb(46, 114, 138);
  width: 100%;
  display: block;
}


.content-scheduler div.item-proyect span.fa-remove,
.content-scheduler div.content-task span.fa-remove {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem;
  cursor: pointer;
}


.modal-content {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.modal-fade {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.modal-content > .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #f2f2f2; */
  background-color: #fff;
  width: 60vmin;
  padding: 1rem 2rem;
  z-index: 2;
}

.modal-content > .modal > .modal-section-top {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-content  .modal > .modal-section-body{
  padding-bottom: 0.5rem;
}

.modal-content > .modal h4 {
  margin-bottom: 1rem;
  color: rgb(46, 114, 138);
}

.modal-content  .modal-section-body > div {
display: grid;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.modal-content > .modal div.input-content {
  display: flex;
  flex-direction: column;
  
}

.modal-content > .modal div.input-content > label {
  margin-bottom: 5px;
}

.modal-content > .modal div.input-content > input {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
  background-color: #f2f2f2;
}

.modal-content > .modal div.input-content > select {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
  box-sizing: border-box;
  background-color: #f2f2f2;
}

.modal-content > .modal div.input-content > textarea {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
  box-sizing: border-box;
  background-color: #f2f2f2;
}

.modal-content > .modal div.input-content > input:hover {
  border: unset;
}

.input-content > .content-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.input-content > .content-options > label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.input-content > .content-options > label > span {
  margin-bottom: 5px;
}
.input-content > .content-options > label > input {
  margin: auto;
}

.modal-content > .modal > div:last-child > span {
  margin: auto;
  display: block;
  padding: 0.7rem 1rem;
  background: rgba(46, 114, 138, 0.8);
  font-family: Montserrat;
  font-size: 11pt;
  border: unset;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 1rem;
  cursor: pointer;
}



.alert-confirm {
  background-color: rgb(46, 114, 138);
  color: white;
  padding: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.alert-confirm p {
  margin-bottom: 1rem;
}

.alert-confirm > div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.item-notification.notification-danger {
  background-color: #e65100;
  color: white;
}
.item-notification.notification-warning {
  background-color: #ffa000;
  color: white;
}
.item-notification.notification-success {
  background-color: #2d718a;
  color: white;
}

.content-task-notification {
  display: grid;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1rem;
}

.content-task-notification > div.item-notification {
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
}

.content-task-notification > div.item-notification > span {
  position: absolute;
  top: 5px;
  right: 5px;
}

.section-quest {
  width: 100%;
  height: 100%;
  /* min-height: calc(100vh - 60px); */
  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 50px; */
  /* padding: 1rem 2rem; */
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .section-quest.content-thumbs-section {
  padding: 1rem 0;
} */

.section-quest.content-thumbs-section{
  align-items: flex-start;
  justify-content: flex-start;
}

.section-quest.quest-itmes-grid {
  display: grid;
  padding: 2rem;
  grid-row-gap: 3rem;
}

.section-quest.content-thumbs-section > button,
.section-quest.quest-itmes-grid > button {
  margin: 0 auto;
}

.section-quest > button:focus {
  border: unset;
  outline: 0;
  /* border-color: rgb(45, 113, 138); */
  font-size: 12pt;
}
.section-quest > button.disable {
  /* background-color: rgba(45, 113, 138, 0.6);
  border-color: rgb(45, 113, 138); */
  color: rgba(0, 0, 0, 0.3);
}

.section-quest > button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: unset;
  padding: 1rem;
  /* margin: auto; */
  cursor: pointer;

  /* background-color: rgb(45, 113, 138);
  border-color: rgb(45, 113, 138);
  font-size: 16pt; */

  border-color: #d6d6d6;
  color: rgba(0, 0, 0, 0.7);
  background-color: #d6d6d6;
  font-size: 12pt;

  transition: 0.5s font-size linear;
}

.welcolme-content {
  align-self: center;
  justify-self: center;
  padding: 2rem;
  text-align: center;
}

.welcolme-content h1 {
  margin: 0;
  font-size: 20pt;
  margin-bottom: 50px;

  font-size: 20pt;
  color: rgba(0, 0, 0, 0.7);
}

.welcolme-content h1 + p {
  font-size: 14pt;
  max-width: 500px;
}

.item-center {
  align-self: center;
  /* justify-self: center; */
  padding: 0 2rem 2rem 2rem;
}
.item-center > h2 {
  margin-top: 0;
}

.center-margin {
  margin: auto;
}

.grid-content.single {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(auto, auto);
}

.grid-content.square {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(auto, auto);
}

.grid-content.qsquare {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(auto, auto);
}

.grid-content > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  /* background: rgba(0, 171, 168, 0.4); */

  background: rgba(0, 0, 0, 0.3);

  padding: 1rem;
}

.grid-content img {
  /* background: rgba(0, 171, 168, 0.4); */
  background: rgba(0, 0, 0, 0.3);

  width: 100%;
  max-width: 30vmin;
  margin: auto;
}
.grid-content img + span {
  font-size: 18pt;
}

.content-questions > .section-quest-item:first-child {
  margin-top: 3rem;
}

.content-questions > .section-quest-item::after {
  content: "";
  position: absolute;
  width: calc(100% - 2rem);
  border-bottom: 1px solid white;
  bottom: -2rem;
  border-radius: 6px;
}

.section-quest-item {
  /* padding: 1rem 2rem; */
  font-family: Heebo;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  max-width: 60vmax;
  /* border: solid 1.7px rgba(0, 171, 168, 0.9);
    margin: auto;
    margin-bottom: 2rem; */
}
@media (min-width: 768px) {
  .section-quest-item {
    border: solid 1.7px rgba(0, 0, 0, 0.7);
  }
}

.section-quest-item p {
  margin: 0;
  margin-bottom: 0.7rem;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14pt;
  font-weight: bold;
}
.answer-content input[type="text"],
.answer-content input[type="number"],
.answer-content textarea {
  /* color: #143845; */
  color: rgba(0, 0, 0, 0.7);
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  /* border: solid 1.7px rgba(45, 113, 138, 0.5) ; */
  border: solid 1.7px rgba(0, 0, 0, 0.3);
  font-size: 12pt;
  /* background-color: rgba(45, 113, 138, 0.7); */
  /* font-family: Heebo; */
  width: 100%;
  box-sizing: border-box;
  /* border-color: rgb(45, 113, 138); */
}

.answer-content textarea:focus {
  outline: 0;
}

.answer-content input[type="radio"] {
  visibility: hidden;
  position: absolute;
}

.answer-content.answer-content-vertical label,
.answer-content.answer-content-horizontal label {
  padding: 0.3rem 0.5rem;
  font-size: 14pt;
}

.answer-content label {
  line-height: 1.3;
  position: relative;
}

.answer-content label.active-radio {
  /* border: #398599 1px SOLID; */
  border: rgba(0, 0, 0, 0.3) 1px SOLID;
}

.grid-content.single span {
  position: relative;
  padding-left: 1rem;
}

.grid-content.single span.active::before,
.answer-content.answer-content-vertical label.active-radio.input-radio::before {
  content: "\2B24";
  color: rgba(0, 0, 0, 0.3);
}

.grid-content.single span::before,
.answer-content.answer-content-vertical .input-radio::before {
  content: "\25EF";
  display: block;
  font-size: 7pt;
  position: absolute;
  top: 50%;
  /* left: 14px; */
  transform: translate(-1.1rem, -50%);
}

.answer-content.answer-content-vertical label {
  padding-left: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.answer-content.answer-content-vertical {
  display: flex;
  max-width: 100%;
  box-sizing: border-box;
  flex-direction: column;
}

.answer-content.answer-content-horizontal {
  display: flex;
  max-width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-around;
}

.quest-thumb-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(auto, 6rem);
  flex-grow: 1;
  padding: 1rem;
  position: relative;

  width: 100%;
  box-sizing: border-box;
}

div.quest-thumb-grid-item {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  padding: 0.5rem;
  box-sizing: border-box;
}

.quest-thumb-grid-item h3 {
  z-index: 57;
  position: inherit;
  color: white;
  margin: 0;
}

.fade-back-thumb {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.fade-back-thumb img {
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
}

.fade-back-thumb span {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: block;
  margin: 0;
}

.quest-thumb-grid .back-thumb {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  /* margin: 1.3rem 1.7rem; */
  font-size: 20pt;
  font-family: Montserrat;
  font-weight: bold;
  z-index: 1;
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.quest-thumb-grid .back-thumb img {
  width: 100%;
}

.quest-thumb-grid-item.check::after {
  content: "\221A";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  z-index: 2;
  font-size: 15pt;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
}

.quest-thumb-grid-item.disable::after {
  content: "\2605";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 29px;
  height: 29px;
  z-index: 2;
  font-size: 15pt;
  background-color: #00aba8;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
}

.content-thumbs-section .section-thumb-item {
  width: 100%;
  margin-bottom: 1rem;
}

.content-thumbs-section .section-thumb-item .horizontal-grid {
  grid-auto-columns: minmax(auto, auto);
  grid-template-columns: unset !important;
  width: 100%;
  max-width: 100vw;
}

.section-thumb-item .horizontal-grid > div {
  grid-row: 1;
  flex-shrink: 1;
  min-width: 7rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;
  border-radius: 10px;
}

.horizontal-grid.extended {
  display: inline-grid;
  grid-template-columns: repeat(6, 60vmin);
  max-width: 100%;
}

.horizontal-grid {
  display: inline-grid;
  grid-template-columns: 60vmin 60vmin;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}

.scroll-hide {
  overflow-x: scroll;
  max-width: 100vw;
  scroll-behavior: smooth;
}

.horizontal-grid > div {
  color: white;
  padding: 1rem 1rem 4rem 1rem;
  position: relative;
}

.horizontal-grid h4 {
  margin: 0;
}
.horizontal-grid p {
  font-size: 12pt;
  margin: 0;
  margin-top: 0.5rem;
  padding: 0 !important;
}

.horizontal-grid span {
  position: absolute;
  bottom: 0;
  right: 0;
  /* margin: 1.3rem 1rem; */
  font-family: Heebo;
}

.horizontal-grid > hr {
  grid-column: 3;
  grid-row: 1;
  visibility: hidden;
}

.section-thumb-item > h3 {
  padding-left: 1rem;
}
.quest-thumb-grid > p {
  padding: 1rem 0;
  grid-column: 1 / 3;
}
.content-thumbs-section > p {
  padding: 1rem;
}

.answer-content .input-range__track--active {
  background: #00aba8;
}

.answer-content .input-range__slider {
  background: #00aba8;
  border: 1px solid #00aba8;
}

.atomic-quest .atomic-quest-selected {
  /* outline: 2px solid #00aba8; */
  outline: 2px solid rgba(0, 0, 0, 0.7);
}

/* .atomic-quest .atomic-quest-selected{
    position: relative;
  }
  .atomic-quest .atomic-quest-selected::before{
    content: '';
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border: 1px white solid;
  } */

.content-answer-matriz {
  display: grid;
  grid-template-columns: 40% repeat(5, 1rem);
  /* grid-template-rows: 1fr; */
  grid-gap: 0.5rem 1rem;
  padding: 1rem;
  box-sizing: border-box;
  align-items: center;
}

.answer-content > .input-range {
  height: 1rem;
  position: relative;
  width: 80%;
  margin: auto;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}

/* content-profile */

.content-profile {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "profile"
    "definition";
  padding: 1rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.content-definition{
  grid-area: definition;
}

.content-avatar-profile{
  grid-area: profile;
}

/* .content-profile .content-definition{
    height: calc(100vh - 7vmin - 2.2rem);
    overflow-y: scroll;
} */

.content-profile .content-avatar-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: flex-start; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.content-profile .content-avatar-profile > p {
  align-self: flex-start;
}

.content-profile .content-definition h2 {
  margin: 0px;
}

.content-profile .content-definition h3 {
  font-size: 14pt;
}

.content-profile .content-definition p,
.content-profile .content-definition h3 {
  margin: 0px;
}

.content-profile .content-definition .content-graph > div {
  padding: 1rem;
  box-sizing: border-box;
}

.content-profile .content-skills > h2 {
  margin-bottom: 0.7rem;
}

.content-powers {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.content-powers > div {
  padding: 1rem;
  background-color: #f2f2f2;
  box-sizing: border-box;
}
.content-profile .content-powers > div h3 {
  margin-bottom: 0.7rem;
}

/* .content-powers{ */


@media (min-width: 768px) {
  .content-profile {
    grid-template-columns: 1fr 30%;
    grid-template-areas:
      "definition profile";
  }
}
