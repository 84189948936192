/* content-feedback-detail */

.content-feedback-detail {
    padding: 1rem;
  }
  
  .content-feedback-detail h2 {
    margin-bottom: 0;
  }
  
  .content-feedback-detail > small {
    display: block;
  }
  .content-feedback-detail > small + span {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .content-feedback-detail > div:not(:last-child) {
    width: 100%;
    margin-bottom: 1.2rem;
    position: relative;
  }
  
  .content-feedback-detail textarea {
    width: 100%;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid white;
    height: 130px;
    box-sizing: border-box;
    font-family: Heebo;
    line-height: 1.2;
    font-size: 11pt;
    z-index: 2;
    position: relative;
    color: #333;
  }
  .content-feedback-detail textarea.tEmpty {
    background-color: rgba(255, 255, 255, 0);
  }
  
  .content-feedback-detail textarea + span {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid white;
    height: 130px;
    box-sizing: border-box;
    font-family: Heebo;
    line-height: 1.2;
    font-size: 11pt;
    opacity: 0;
    color: #6d6d6d;
  }
  
  .content-feedback-detail textarea.tEmpty + span {
    opacity: 1;
  }
  
  .content-feedback-detail button {
    margin: auto;
    display: block;
    padding: 0.7rem 1rem;
    background: white;
    font-family: Montserrat;
    font-size: 11pt;
    border: unset;
  }
  .content-feedback-detail button.disable {
    background: rgb(255, 255, 255, 0.7);
    color: #6d6d6d;
  }
  
  .content-feedback-detail > .content-description{
      margin-bottom: 1rem;
      display: block;
  }

  .content-feedback-detail .content-feedback-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .content-feedback-detail .content-feedback-options .content-feedback-options-item .check,
  .content-feedback-detail .content-feedback-options .content-feedback-options-item .check-dash {
    display: none;
  }
  
  .content-feedback-detail .content-feedback-options .content-feedback-options-item.active .check,
  .content-feedback-detail .content-feedback-options .content-feedback-options-item.active .check-dash {
    display: inherit;
  }

  .content-feedback-options-item .option-icon-check{
      display: block;
      width: 30px;
      height: 30px;
      margin: auto ;
  }
  
  .content-feedback-detail .content-feedback-options-item {
    font-size: 10pt;
    display: flex;
    flex-direction: column;
    text-align: center;
  }