/* .content-breathing-tools */

.content-breathing-tools {
  padding: 1rem;
  box-sizing: border-box;
}

.content-breathing-tools h2 {
  margin: 0px;
  margin-bottom: 1rem;
}

.content-breathing-tools .content-tools {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
}

@media (min-width: 768px) {
  .content-breathing-tools .content-tools {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}

@media (min-width: 1024px) {
  .content-breathing-tools .content-tools {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
}

.content-breathing-tools .content-tools > div {
  padding: 1rem;
  box-sizing: border-box;
  background-color: #f2f2f2;
}

.content-breathing-tools .content-tools > div h4 {
  margin: 0px;
}

.content-breathing-tools .content-main-tool {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.content-breathing-tools .content-main-tool .breath-item-current {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: #f2f2f2;
}

.content-breathing-tools
  .content-main-tool
  .breath-item-current
  > div:first-child {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.content-breathing-tools
  .content-main-tool
  .breath-item-current
  > div:last-child {
  text-align: right;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.content-breathing-tools .content-main-tool .breath-item-current > div > div {
  padding: 1rem;
  box-sizing: border-box;
}

.content-breathing-tools
  .content-main-tool
  .breath-item-current
  > div
  > div:last-child
  > span {
  display: flex;
  clear: both;
  margin-bottom: 0.1rem;
  justify-content: space-between;
}

.content-breathing-tools .content-main-tool .breath-item-current h4,
.content-breathing-tools .content-main-tool .breath-item-current p {
  margin: 0;
}
