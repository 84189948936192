.content-retrospective{
    padding: 1rem;
}

.content-retrospective h2{
    margin: 0;
    margin-bottom: 2rem;
}

.content-comments{
    display: grid;
    grid-template-columns: 1rf;
    row-gap: 1rem;
}

.content-comments > div{
    padding: 1rem;
    background-color: #F2F2F2;
}