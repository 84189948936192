.content-login {
  display: grid;
  position: fixed;
  height: 100%;
  width: 100%;

  /* grid-template-columns: repeat(2, 1fr); */
  /* height: 100vh; */
  background-color: #000;
  /* background-color: #D6D6D6; */
}

.content-login .brand-content {
  background-position: top;
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.content-login .brand-content > div {
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-login .brand-content img {
  max-height: 10vmin;
  width: auto;
}

.content-login .form-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.content-login .form-content > div {
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  padding: 2rem;
  width: 400px;
  box-sizing: border-box;
  max-width: 90vmin;
}
.content-login .form-content .form-section h2 {
  color: #398599;
  box-sizing: border-box;
}

.content-login .form-section {
  box-sizing: border-box;
  position: relative;
}

.content-login .form-section input {
  border: none;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: 16pt;
  border-bottom: 1.3px solid rgba(255, 255, 255, 0.7);
  color: white;
  background-color: transparent;
  position: relative;
  outline: unset;
}

.content-login .form-section.unactive::after {
  content: "Ingrese su c\f3 digo|";
  position: absolute;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16pt;
  width: 100%;
  left: 0.5rem;
  bottom: 0.5rem;
  animation: textInputEmpty 1.3s infinite;
}

@keyframes textInputEmpty {
  0% {
    content: "Ingrese su código|";
  }
  50% {
    content: "Ingrese su código";
  }
}

.content-login .form-section > span {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: rgba(51, 51, 51, 0.5);
  font-size: 14pt;
}

.content-login .form-content > div button {
  padding: 0.5rem 1rem;
  font-size: 14pt;
  /* border-radius: 40px; */
  /* width: 200px; */
  /* color: white; */
  margin: auto;
  display: block;
  margin-top: 5rem;
  border: unset;
  /* background-repeat: no-repeat; */
  /* background-size: contain; */
  /* background-position: left; */
  background: none !important;
  color: rgba(255, 255, 255, 0.8);
  border: 1px rgba(255, 255, 255, 0.7) solid;
  border-radius: 5px;
  letter-spacing: 2px;
}
